export default {
  props: {
  },
  computed: {
    convertLabel() {
      return this.label ? this.$comm.getLangLabel(this.label) : ''
    },
    convertTitle() {
      return this.title ? this.$comm.getLangLabel(this.title) : ''
    },
    convertPlaceholder() {
      return this.placeholder ? this.$comm.getLangLabel(this.placeholder) : ''
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
}
