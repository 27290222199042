var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "q-pa-xs",
      staticStyle: { background: "#fff", float: "left", width: "100%" },
    },
    [
      _c(
        "q-breadcrumbs",
        { attrs: { gutter: "xs" } },
        [
          _c("q-breadcrumbs-el", {
            staticStyle: { float: "left" },
            attrs: { icon: "home", to: "/" },
          }),
          _c("q-breadcrumbs-el", {
            staticStyle: { float: "left" },
            attrs: {
              label: "Docs",
              icon: "widgets",
              to: "/start/pick-quasar-flavour",
            },
          }),
          _c("q-breadcrumbs-el", {
            staticStyle: { float: "left" },
            attrs: {
              label: "Breadcrumbs",
              icon: "navigation",
              to: "/vue-components/breadcrumbs",
            },
          }),
          _c("q-breadcrumbs-el", {
            staticStyle: { float: "left" },
            attrs: { label: "Build", icon: "build" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }