<!--
목적 : 확장검색 기능을 지원하는 컴포넌트
Detail :
 *
examples:
 *
-->
<template>
  <div class="td-input-column">
    <div v-if="!isShow" class="pad-txt-label">
      <div v-if="col.disableData && disabled && props.row[col.name] == 0"
        tabindex="0" 
        ref="input-text"
        :class="['text-center']"
        @focus="numberfocus()"
        @click="numberfocus()" >
        -
      </div>
      <div v-else
        tabindex="0" 
        ref="input-text"
        :class="['text-right', disabled ? '' : 'table-td-numberlabel', colorClass? colorClass: '']"
        @focus="numberfocus()"
        @click="numberfocus()" >
        {{ props.row[col.name] | toThousandFilter }}
      </div>
    </div>
    <div v-if="editable&&isShow">
      <q-input
        ref="input-column"
        class="table-td-text"
        type="number"
        outlined autofocus
        :disable="disabled"
        v-model="props.row[col.name]"
        @input="datachange"
        @blur="numberfocusout()"
      ></q-input>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import mixinCommon from './js/mixin-common'
export default {
  /* attributes: name, components, props, data */
  name: 'c-number-column',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
    },
    colorClass: {
      type: String,
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    col: {
      type: Object,
      default: function() {
        return {
          taskClassCd: '',
          taskKey: '',
        }
      }
    },
    props: {
      type: Object,
      default: function() {
        return { }
      }
    },
    numberOptions: {
      type: Object,
      default: () => {
        return {
          currency: null,
        }
      }
    }
  },
  data() {
    return {
      isShow: '',
    };
  },
  computed: {
  },
  watch: {
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {},
  destroyed() {},
  updated() {
  },
  /* methods */
  methods: {
    init() {
      // url setting
    },
    numberfocus() {
      if (this.editable&&!this.disabled) {
        this.$set(this.$data, 'isShow', true)
      }
    },
    numberfocusout() {
      this.$set(this.$data, 'isShow', false)
    },
    datachange() {
      this.$emit('datachange')
    }
  },
};
</script>