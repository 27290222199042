var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fix-height" },
    [
      _c("q-field", {
        ref: "customCheckbox",
        staticClass: "q-field--float customCheck",
        class: _vm.items && _vm.items.length <= 2 ? "under_border" : "",
        attrs: {
          label: _vm.convertLabel,
          filled: "",
          value: _vm.vValue,
          color: "orange-7",
          rules: [_vm.myRule],
          disable: !_vm.editable || _vm.disabled,
          dense: "",
        },
        scopedSlots: _vm._u(
          [
            _vm.label
              ? {
                  key: "label",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "row items-center all-pointer-events" },
                        [
                          _c(
                            "b",
                            [
                              !_vm.required
                                ? _c("i", {
                                    staticClass:
                                      "pe-7s-note labelfrontIcon searchAreaLabelIcon",
                                  })
                                : _vm._e(),
                              _c("font", { staticClass: "formLabelTitle" }, [
                                _vm._v(_vm._s(_vm.convertLabel)),
                              ]),
                              _vm.required
                                ? _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons labelfrontIcon text-requiredColor",
                                    },
                                    [_vm._v("check")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "control",
              fn: function () {
                return [
                  !_vm.isFlag
                    ? [
                        _c("q-option-group", {
                          staticClass: "q-pb-xs CoptionGroup",
                          attrs: {
                            options: _vm.items,
                            disable: _vm.disabled || !_vm.editable,
                            name: _vm.name,
                            dense: _vm.dense,
                            inline: _vm.inline,
                            val: "",
                            label: "",
                            color: "orange-7",
                            type: "checkbox",
                          },
                          on: { input: _vm.input },
                          model: {
                            value: _vm.vValue,
                            callback: function ($$v) {
                              _vm.vValue = $$v
                            },
                            expression: "vValue",
                          },
                        }),
                      ]
                    : [
                        _c(
                          "div",
                          { staticClass: "q-pb-xs CoptionGroup" },
                          [
                            _c("q-checkbox", {
                              attrs: {
                                dense: _vm.dense,
                                color: "orange-7",
                                label: _vm.setFlagLabel,
                                "true-value": _vm.trueValue,
                                "false-value": _vm.falseValue,
                              },
                              on: { input: _vm.input },
                              model: {
                                value: _vm.vValue,
                                callback: function ($$v) {
                                  _vm.vValue = $$v
                                },
                                expression: "vValue",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }