import Compressor from 'compressorjs';
export default {
  props: {
  },
  data() {
    return {
      queuedFileInfo: {
        files: [],
        compressFiles: [],
        len: 0,
        isStart: false,
        isUpload: false,
      },
      /** compressor Data */
      options: {
        strict: true,
        checkOrientation: true,
        maxWidth: 600,
        maxHeight: undefined,
        minWidth: 0,
        minHeight: 0,
        width: undefined,
        height: undefined,
        resize: 'contain', // none contain cover
        quality: 0.1,
        mimeType: '',
        convertTypes: 'image/png',
        convertSize: 5000000,
        success: () => { },
        error: () => { },
        // success: this.successCompress,
        // error: this.errorCompress,
      },
      // inputURL: '',
      // input: {},
    };
  },
  computed: {
  },
  watch: {
    // 'queuedFileInfo.len'() {
    // },
  },
  methods: {
    setCompressOptions() {
      this.$set(this.options, 'success', this.successCompress)
      this.$set(this.options, 'error', this.errorCompress)
    },
    compressCheck(files, uploadSetting) {
      if (files && files.length > 0) {
        this.$set(this.queuedFileInfo, 'files', files)
        this.$set(this.queuedFileInfo, 'compressFiles', [])
        this.$set(this.queuedFileInfo, 'isStart', true)
        if (uploadSetting) {
          if (uploadSetting.resizeWidth === 0) {
            this.$set(this.options, 'maxWidth', undefined)
            this.$set(this.options, 'quality', 1)
          } else {
            this.$set(this.options, 'maxWidth', uploadSetting.resizeWidth)
            this.$set(this.options, 'quality', uploadSetting.resizeQuality)
          }
        }
         // 이미지 파일을 포함한 모든 파일의 갯수
        this.$set(this.queuedFileInfo, 'len', files.length)
        this.$_.forEach(this.queuedFileInfo.files, _file => {
          this.compress(_file);
        })
      }
    },
    compress: function (file) {
      if (!file) {
        return;
      }
      const reg = /(gif|jpe?g|tiff?|png|webp|bmp)$/g;
      let _check = reg.test(file.type);
      if (_check) {
        // if (URL) {
        //   this.inputURL = URL.createObjectURL(file);
        // }
        // this.input = file;
        new Compressor(file, this.options);
        // 이미지
      } else {
        if (!this.queuedFileInfo.compressFiles) this.queuedFileInfo.compressFiles = [];
        this.queuedFileInfo.compressFiles.push(file)
        this.queuedLenMi();
      }
    },
    successCompress(result) {
      // if (URL) {
      //   this.outputURL = URL.createObjectURL(result);
      // }
      if (!this.queuedFileInfo.compressFiles) this.queuedFileInfo.compressFiles = [];
      this.queuedFileInfo.compressFiles.push(result)
      this.queuedLenMi();
    },
    errorCompress(err) {
      console.log(err)
    },
    reset() {
      Object.assign(this.$data.queuedFileInfo, this.$options.data().queuedFileInfo);
    },
    queuedLenMi() {
      this.$set(this.queuedFileInfo, 'len', this.queuedFileInfo.len - 1)
      // this.queuedFileInfo.len--;

      if (this.queuedFileInfo.isStart && this.queuedFileInfo.len === 0) {
        this.$refs[this.uploadRef].removeQueuedFiles();
        this.$set(this.queuedFileInfo, 'isUpload', true)
        setTimeout(() => {
          this.$refs[this.uploadRef].addFiles(this.queuedFileInfo.compressFiles);
  
          this.$refs[this.uploadRef].upload();
        }, 500);
      }
    },
  },
}
