var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fix-height" }, [
    _c("div", { staticClass: "row items-center all-pointer-events" }, [
      _c(
        "b",
        [
          _c("i", { staticClass: "pe-7s-comment lblIcon" }),
          _c("font", { staticClass: "lbltitle" }, [
            _vm._v(_vm._s(_vm.convertTitle)),
          ]),
        ],
        1
      ),
    ]),
    _vm.view === "text"
      ? _c("div", {
          staticClass: "lblvalue",
          style: _vm.setStyle,
          domProps: { innerHTML: _vm._s(_vm.convertValue) },
        })
      : _c(
          "div",
          { staticClass: "lblvalue", style: _vm.setStyle },
          [_vm._t("contents")],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }