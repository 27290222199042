<!--
목적 : 확장검색 기능을 지원하는 컴포넌트
Detail :
 *
examples:
 *  presentation
-->
<template>
  <div class="fix-height">
    <q-field
      ref="customCheckbox"
      class="q-field--float customRadio"
      :label="convertLabel"
      :value="vValue"
      filled
      :rules="[myRule]"
      color="orange-7"
      :disable="!editable || disabled"
      dense
    >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{convertLabel}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        <q-option-group
          :options="items"
          :disable="disabled || !editable"
          :name="name"
          dense
          inline
          val="orange-7"
          label="orange-7"
          color="orange-7"
          type="radio"
          class="q-pb-xs CoptionGroup"
          v-model="vValue"
          @input="input" />
      </template>
    </q-field>
  </div>
</template>

<script>
import mixinCommon from './js/mixin-common'
export default {
  /* attributes: name, components, props, data */
  name: 'c-radio',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: {
      type: [String, Number],
    },
    label: {
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    comboItems: {
      type: Array,
    },
    itemText: {
      type: String,
      default: 'codeName',
    },
    itemValue: {
      type: String,
      default: 'code',
    },
    codeGroupCd: {
      type: String,
      default: ''
    },
    codeAttrVal1: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      items: [],
      vValue: null,
    };
  },
  watch: {
    // TODO : 부모의 v-model 변경을 감시(예를 들면, db로부터 데이터를 조회 한 후 값을 바인딩 할 경우)
    value() {
      this.vValue = this.$_.clone(this.value);
    },
    // 부모로 부터 값을 비동기로 가져올 경우 처리
    comboItems: {
      handler: function () {
        this.makeSelectOptions(this.comboItems);
        this.vValue = this.value;
      },
      deep: true,
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {},
  destroyed() {},
  updated() {
  },
  /* methods */
  methods: {
    init() {
      this.vValue = this.$_.clone(this.value);
      if (this.comboItems && this.comboItems.length > 0) {
        this.makeSelectOptions(this.comboItems);
      } else if (this.codeGroupCd) {
        this.getItems();
      }
    },
    input() {
      this.$emit('input', this.vValue)
      this.$emit('datachange', this.vValue);
    },
    getItems() {
      this.$comm.getComboItems(this.codeGroupCd).then(_result => {
        this.makeSelectOptions(_result);
        this.$emit('setCodeData')
      });
    },
    makeSelectOptions(items) {
      var options = [];
      if (!items || items.length === 0) return options;
      let tempcomboItems = [];
      // 쓰기권환이 있으며 활성화 상태인 경우
      if (this.editable && !this.disabled) {
        // 사용여부가 Y인 것만 리스트에 표현한다.
        // default : 사용여부 상관없이 전체
        tempcomboItems = this.$_.reject(items, { useFlag: 'N' });
      } else {
        tempcomboItems = items;
      }
      // 추가속성으로 조회조건이 추가적으로있을경우 필터링
      if (this.codeAttrVal1) {
        tempcomboItems = this.$_.filter(tempcomboItems, {attrVal1 : this.codeAttrVal1 });
      }

      this.$_.forEach(tempcomboItems, (_item) => {
        options.push({
          label: _item[this.itemText],
          value: _item[this.itemValue],
        });
      });
      this.items = options;
    },
    myRule(val) {
      return !this.required || (val && val.length > 0) || ''
    }
  },
};
</script>
<style lang="sass">
.CoptionGroup
  padding-left: 0px
  padding-bottom: 0px
  margin-bottom: -5px
  font-size: 12px
  .q-radio__inner
    width: 0.35em
    min-width: 0.35em
    height: 0.35em
  .q-radio__bg
    width: 0.35em
    min-width: 0.35em
    height: 0.35em
  .q-radio__label
    padding-left: 0.2em
    padding-right: 0.6em
.customRadio
  .q-field__native
    padding-bottom: 6px !important
  .q-field__label
    margin-left: 5px
  .text-negative
    margin-top: 15px
.customRadio.q-field--dense .q-field__control, .customRadio.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 33px !important

.customRadio.q-field--filled .q-field__control
  padding: 0px 8px 0px 2px

</style>