<!--
  TODO : 스크롤 영역 처리
-->
<template>
  <div :style="{'height': `${height}px)`}">
    <VuePerfectScrollbar class="scrollbar-container" v-once>
      <slot></slot>
    </VuePerfectScrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
export default {
  /* attributes: name, components, props, data */
  name: 'scroll-container',
  components: {
    VuePerfectScrollbar
  },
  props: {
  },
  data() {
    return {
      height: '100%',
    };
  },
  computed: {
  },
  watch: {
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    // 윈도우 resize event
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestory() {
    window.removeEventListener('resize', this.setGridSize);
  },
  /* methods */
  methods: {
    init() {
      this.setSize();
    },
    setSize() {
      this.height = window.innerHeight - 230;
    }
  },
};
</script>
