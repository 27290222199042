<template>
  <div class="q-pa-xs" style="background:#fff;float:left;width:100%;">
    <q-breadcrumbs gutter="xs">
      <q-breadcrumbs-el icon="home" to="/" style="float:left;" />
      <q-breadcrumbs-el label="Docs" icon="widgets" to="/start/pick-quasar-flavour" style="float:left;" />
      <q-breadcrumbs-el label="Breadcrumbs" icon="navigation" to="/vue-components/breadcrumbs" style="float:left;" />
      <q-breadcrumbs-el label="Build" icon="build" style="float:left;" />
    </q-breadcrumbs>
  </div>
</template>

<script>
export default {
  name: "Header",
  components: {
  },
  data() {
    return {
    };
  },
  props: {
  },
  methods: {
  }
};
</script>
