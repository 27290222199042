var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-card",
    {
      staticClass: "default-card searchArea",
      attrs: { flat: "", bordered: "" },
    },
    [
      _c(
        "div",
        {
          staticClass: "row searchArearow",
          on: {
            mouseover: _vm.onMouseOver,
            mouseout: _vm.onMouseOut,
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.onEnterKey.apply(null, arguments)
            },
          },
        },
        [_vm._t("search")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }