var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { style: { height: `${_vm.height}px)` } }, [_vm._m(0)], 1)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "VuePerfectScrollbar",
      { staticClass: "scrollbar-container" },
      [_vm._t("default")],
      2
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }