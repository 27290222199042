var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-tooltip",
    {
      attrs: {
        "content-class": _vm.contentClass,
        "content-style": _vm.contentStyle,
      },
    },
    [
      _c(
        "q-list",
        [
          _c(
            "q-item",
            [
              _c(
                "q-item-section",
                [
                  _c("q-item-label", { staticClass: "text-black" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                  _c("q-item-label", { attrs: { caption: "", lines: "2" } }, [
                    _vm._v(_vm._s(_vm.contents["ibm_title"].text)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }