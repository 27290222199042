var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "app-sidebar sidebar-shadow",
      class: _vm.sidebarbg,
      on: {
        mouseover: function ($event) {
          return _vm.toggleSidebarHover("add", "closed-sidebar-open")
        },
        mouseleave: function ($event) {
          return _vm.toggleSidebarHover("remove", "closed-sidebar-open")
        },
      },
    },
    [
      _c("div", { staticClass: "app-header__logo" }, [
        _c("div", {
          staticClass: "logo-src cursor-pointer anivertilogo",
          on: { click: _vm.routeMain },
        }),
      ]),
      _c(
        "div",
        { staticClass: "app-sidebar-content" },
        [
          _c(
            "VuePerfectScrollbar",
            { staticClass: "app-sidebar-scroll left-sidebar-menu" },
            [
              _c("c-sidebar-menu", {
                attrs: { showOneChild: "", menu: _vm.permission_routers },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }