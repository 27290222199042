var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "td-input-column" }, [
    !_vm.isShow
      ? _c("div", { staticClass: "pad-txt-label" }, [
          _c("div", {
            staticClass: "table-td-textlabel",
            attrs: { tabindex: "0" },
            domProps: {
              innerHTML: _vm._s(
                _vm.$comm.convertEnter(_vm.props.row[_vm.col.name])
              ),
            },
            on: {
              focus: function ($event) {
                return _vm.textareafocus()
              },
              click: function ($event) {
                return _vm.textareafocus()
              },
            },
          }),
        ])
      : _vm._e(),
    _vm.editable && _vm.isShow
      ? _c(
          "div",
          [
            _c("q-input", {
              ref: "input-column",
              staticClass: "table-td-text",
              attrs: {
                type: "textarea",
                outlined: "",
                autofocus: "",
                autogrow: true,
                disable: _vm.disabled,
              },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.stopPropagation()
                },
                input: function ($event) {
                  return _vm.datachange(_vm.props, _vm.col)
                },
                blur: function ($event) {
                  return _vm.textareafocusout()
                },
              },
              model: {
                value: _vm.props.row[_vm.col.name],
                callback: function ($$v) {
                  _vm.$set(_vm.props.row, _vm.col.name, $$v)
                },
                expression: "props.row[col.name]",
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }