var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vsm-list" },
    _vm._l(_vm.items, function (subItem, index) {
      return _c(
        "item",
        {
          key: index,
          attrs: { item: subItem, "show-child": _vm.showChild, rtl: _vm.rtl },
        },
        [_vm._t("dropdown-icon", null, { slot: "dropdown-icon" })],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }