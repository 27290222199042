var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("c-select", {
    attrs: {
      stype: _vm.stype,
      comboItems: _vm.plantItems,
      editable: _vm.editable,
      required: _vm.required,
      disabled: _vm.disabled || _vm.roleAccess,
      type: _vm.type,
      itemText: "codeName",
      itemValue: "code",
      label: _vm.label,
      name: "plantCd",
    },
    on: {
      datachange: (val) => {
        _vm.datachange(val)
      },
    },
    model: {
      value: _vm.plantCd,
      callback: function ($$v) {
        _vm.plantCd = $$v
      },
      expression: "plantCd",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }