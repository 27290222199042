var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container app-theme-white" },
    [
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in", appear: "" } },
        [_c("Header", { attrs: { headerbg: _vm.headercolor } })],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in", appear: "" } },
        [_c("Sidebar", { attrs: { sidebarbg: _vm.sidebarcolor } })],
        1
      ),
      _c(
        "div",
        { staticClass: "app-main__outer" },
        [
          _c(
            "div",
            { staticClass: "app-main__inner p-0" },
            [_vm._t("default")],
            2
          ),
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in", appear: "" } },
            [_c("Footer")],
            1
          ),
        ],
        1
      ),
      _c("b-modal", { attrs: { id: "modal1", title: "Bootstrap-Vue" } }, [
        _c("p", { staticClass: "my-4" }, [_vm._v("Hello from modal!")]),
      ]),
      _c(
        "b-modal",
        { attrs: { id: "modallg", size: "lg", title: "Large Modal" } },
        [_vm._v("Hello Modal!")]
      ),
      _c(
        "b-modal",
        { attrs: { id: "modalsm", size: "sm", title: "Small Modal" } },
        [_vm._v("Hello Modal!")]
      ),
      _c("div", { staticClass: "ui-theme-settings" }, [
        _c(
          "button",
          {
            staticClass: "btn-open-options btn btn-warning",
            class: { "is-active": _vm.isConfigOpen },
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.toggleConfig("settings-open")
              },
            },
          },
          [
            _c("font-awesome-icon", {
              attrs: { icon: "cog", size: "2x", spin: "" },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "theme-settings__inner" }, [_vm._m(0)], 1),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("VuePerfectScrollbar", { staticClass: "scrollbar-container" }, [
      _c("div", { staticClass: "theme-settings__options-wrapper" }, [
        _c("h3", { staticClass: "themeoptions-heading" }, [
          _c("div", [_vm._v("Header Style")]),
          _c(
            "button",
            {
              staticClass:
                "btn-pill btn-shadow btn-wide ml-auto btn btn-focus btn-sm",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  _vm.headercolor = ""
                },
              },
            },
            [_vm._v(" Restore Default ")]
          ),
        ]),
        _c("div", { staticClass: "p-3" }, [
          _c("ul", { staticClass: "list-group" }, [
            _c("li", { staticClass: "list-group-item" }, [
              _c("h5", { staticClass: "pb-2" }, [
                _vm._v("Choose Color Scheme"),
              ]),
              _c(
                "div",
                { staticClass: "theme-settings-swatches" },
                _vm._l(_vm.colors, function (color) {
                  return _c("div", {
                    key: color,
                    staticClass: "swatch-holder swatch-holder-md",
                    class: color,
                    on: {
                      click: function ($event) {
                        _vm.headercolor = color
                      },
                    },
                  })
                }),
                0
              ),
            ]),
          ]),
        ]),
        _c("h3", { staticClass: "themeoptions-heading" }, [
          _c("div", [_vm._v("Sidebar Style")]),
          _c(
            "button",
            {
              staticClass:
                "btn-pill btn-shadow btn-wide ml-auto btn btn-focus btn-sm",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  _vm.sidebarcolor = ""
                },
              },
            },
            [_vm._v("Restore Default ")]
          ),
        ]),
        _c("div", { staticClass: "p-3" }, [
          _c("ul", { staticClass: "list-group" }, [
            _c("li", { staticClass: "list-group-item" }, [
              _c("h5", { staticClass: "pb-2" }, [
                _vm._v("Choose Color Scheme"),
              ]),
              _c(
                "div",
                { staticClass: "theme-settings-swatches" },
                _vm._l(_vm.colors, function (color) {
                  return _c("div", {
                    key: color,
                    staticClass: "swatch-holder swatch-holder-md",
                    class: color,
                    on: {
                      click: function ($event) {
                        _vm.sidebarcolor = color
                      },
                    },
                  })
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }