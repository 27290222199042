<!--
  TODO : 버튼 공통 모듈
-->
<template>
<div></div>
</template>

<script>
/* eslint-disable */
export default {
  /* attributes: name, components, props, data */
  name: 'c-win-pop',
  props: {
  },
  data() {
    return {
      windowRef : null,
    };
  },
  computed: {
    isClosed() {
      return this.windowRef ? this.windowRef.closed : null
    }
  },
  watch: {
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  mounted() {
  },
  /* methods */
  methods: {
    openWinPop(uri, width, height) {
      if (this.windowRef){
        this.closeWinPop();
      }

      let left = 150; // (screen.width) ? (screen.width - width) / 2 : 0;
      let top = 150; // (screen.height) ? (screen.height - height) / 2 : 0;

      let attr = 'top=' + top + ', left=' + left  + ', width=' + width + ', height=' + height + ', resizable=no,status=no';

      // 1. 윈도우 팝업 띄우기 
      this.windowRef = window.open(uri, "", attr);

      // 2.  새로 띄운 윈도우 팝업창으로 부터 수신 메세지 이벤트 처리 
      window.addEventListener("message", this.recvEvtFromChild, false);
    },
    closeWinPop() {
      // 윈도우 팝업 닫기 
      if (this.windowRef) {
        this.windowRef.close();
        this.windowRef = null;
        this.$emit('onClose');
      }
    },
    sendEvtToChild(evt) {
      // 3. 부모창에서 팝업창에 메세지 보내기 
      if (!this.windowRef || !g_winPopup) {
        return;
      }

      // 4. 윈도팝업창(g_winPopup)에 함수 실행 
      //  g_winPopup 변수는 본 소스에서 선언하지 않고 아래 ChildWinPop.vue 소스인 윈도우 팝업창 측에서 선언하고 설정함
      g_winPopup.calledFromOpener(evt);
    },
    recvEvtFromChild(evt) {
      // 5. 팝업창으로 부터 수신된 이벤트 
      if (!evt.data){
        return;
      } else if (evt.data === 'CLOSE') {
        this.closeWinPop();
        return;
      }
      
      // 5. 본 소스 WinPop.vue를 콤포넌트로 사용하는 부모 vue 측에 이벤트 전달
      this.$emit('onRecvEvtFromWinPop', evt.data);
    },
  },
};
</script>

