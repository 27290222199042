<template>
  <div id="benesApp" class="v-application">
    <div class="app-container app-theme-white">
    </div>
  </div>
</template>

<script>

export default {
  name: "app",
  components: {
  },
  methods: {
  },

  data() {
    return {
    };
  }
};
</script>