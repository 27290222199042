var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fix-height" },
    [
      _c("q-file", {
        ref: "custom-pick-file",
        staticClass: "customFilePick",
        class: [_vm.beforeText ? "before-field" : ""],
        attrs: {
          "stack-label": "",
          filled: "",
          color: "orange-7",
          label: _vm.convertLabel,
          disable: _vm.disabled || !_vm.editable,
          dense: _vm.dense,
          loading: _vm.loading,
          clearable: !_vm.disabled && _vm.editable,
        },
        on: { input: _vm.input, clear: _vm.clear },
        scopedSlots: _vm._u(
          [
            {
              key: "prepend",
              fn: function () {
                return [_c("q-icon", { attrs: { name: "attach_file" } })]
              },
              proxy: true,
            },
            _vm.label
              ? {
                  key: "label",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "row items-center all-pointer-events" },
                        [
                          _c(
                            "b",
                            [
                              _c("i", {
                                staticClass: "pe-7s-note labelfrontIcon",
                              }),
                              _c("font", { staticClass: "formLabelTitle" }, [
                                _vm._v(_vm._s(_vm.convertLabel)),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "before",
              fn: function () {
                return [
                  _vm.beforeText
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "custom-text-before q-pl-xs q-pr-xs bg-blue-7 text-white",
                          },
                          [_vm._v(" " + _vm._s(_vm.beforeText) + " ")]
                        ),
                      ]
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.vValue,
          callback: function ($$v) {
            _vm.vValue = $$v
          },
          expression: "vValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }