var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isUploaderSetting
    ? _c(
        "div",
        { staticClass: "fix-height row upload-cover2" },
        [
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
            [
              _c(
                "q-list",
                {
                  staticClass: "uploaded-file-list2",
                  attrs: { separator: "", bordered: "" },
                },
                [
                  _vm.fileList && _vm.fileList.length > 0
                    ? _vm._l(_vm.fileList, function (file, idx) {
                        return _c(
                          "q-item",
                          { key: idx, staticClass: "fileuploadItem" },
                          [
                            _c(
                              "q-item-section",
                              { staticClass: "fileuploadColumn" },
                              [
                                _c(
                                  "q-item-label",
                                  {
                                    staticClass: "full-width file-origin-name",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "text-blue cursor-pointer",
                                        staticStyle: {
                                          "font-size": "0.95em !important",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.fileDown(file)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(file.oriFileNm) + " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "text-caption" },
                                      [
                                        _vm._v(
                                          " (" +
                                            _vm._s(
                                              _vm.getFileSizeTextByRound(
                                                file.fileSize
                                              )
                                            ) +
                                            ") "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm.uploaderSetting.explainFlag === "Y"
                                  ? _c(
                                      "q-item-label",
                                      [
                                        _c("c-text", {
                                          staticClass: "file-description",
                                          attrs: {
                                            editable: _vm.editable,
                                            name: "description",
                                            placeholder: "파일설명",
                                          },
                                          model: {
                                            value: file.description,
                                            callback: function ($$v) {
                                              _vm.$set(file, "description", $$v)
                                            },
                                            expression: "file.description",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      })
                    : [
                        _c(
                          "q-item",
                          { staticClass: "fileuploadItem nofileArea2" },
                          [_vm._v(" 업로드된 파일이 없습니다. ")]
                        ),
                      ],
                ],
                2
              ),
            ],
            1
          ),
          _vm.preview &&
          (_vm.preview.src ||
            (_vm.preview.fileList && _vm.preview.fileList.length > 0))
            ? _c(
                "q-dialog",
                {
                  model: {
                    value: _vm.preview.isShow,
                    callback: function ($$v) {
                      _vm.$set(_vm.preview, "isShow", $$v)
                    },
                    expression: "preview.isShow",
                  },
                },
                [
                  _c(
                    "q-card",
                    { staticClass: "preview-image-card" },
                    [
                      _vm.preview.fileList && _vm.preview.fileList.length > 0
                        ? _c(
                            "q-carousel",
                            {
                              attrs: {
                                swipeable: "",
                                animated: "",
                                thumbnails: "",
                                infinite: "",
                              },
                              on: { transition: _vm.transitionImage },
                              model: {
                                value: _vm.preview.slide,
                                callback: function ($$v) {
                                  _vm.$set(_vm.preview, "slide", $$v)
                                },
                                expression: "preview.slide",
                              },
                            },
                            _vm._l(_vm.preview.fileList, function (file, idx) {
                              return _c("q-carousel-slide", {
                                key: idx,
                                attrs: {
                                  name: file.sysAttachFileId,
                                  "img-src": file.src,
                                },
                              })
                            }),
                            1
                          )
                        : _c("q-img", { attrs: { src: _vm.preview.src } }),
                      _c(
                        "q-card-section",
                        [
                          _c("q-btn", {
                            staticClass: "absolute",
                            staticStyle: {
                              top: "0",
                              right: "12px",
                              transform: "translateY(-50%)",
                            },
                            attrs: {
                              fab: "",
                              color: "primary",
                              icon: "save_alt",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.fileDown(_vm.preview)
                              },
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "row no-wrap items-center" },
                            [
                              _c("div", { staticClass: "col text-h6" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.preview.oriFileNm) + " "
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-auto text-grey text-caption q-pt-md row no-wrap items-center",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getFileSizeTextByRound(
                                          _vm.preview.fileSize
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.preview.description ? _c("q-separator") : _vm._e(),
                      _vm.preview.description
                        ? _c("q-card-section", { staticClass: "q-pt-none" }, [
                            _c(
                              "div",
                              { staticClass: "text-subtitle1" },
                              [
                                _c("q-icon", { attrs: { name: "info" } }),
                                _vm._v(" 파일 설명 "),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "text-caption text-grey" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.preview.description) + " "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }