/* eslint-disable */
import request from '@/utils/request';
import comm from '@/js/common';

export function getLangLabelJson() {
  return request({
    url: comm.getLangLabelJsonApi(),
    method: 'GET',
  });
}
export function getLangMessageJson() {
  return request({
    url: comm.getLangMessageJsonApi(),
    method: 'GET',
  });
}

export function getLang() {
  return new Promise((resolve, reject) => {
    resolve({
      lbls: getLangLabelJson(),
      msgs: getLangMessageJson(),
    })
  // return {
  //   lbls: getLangLabelJson(),
  //   msgs: getLangMessageJson(),
  // };
  });
}

const lang = {
  state: {
    lbls: [],
    msgs: [],
  },
  mutations: {
    SET_LABEL: (state, labels) => {
      state.lbls = labels
    },
    SET_MESSAGE: (state, messages) => {
      state.msgs = messages
    },
  },
  actions: {
    SetLangJson({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        // 라벨정보
        getLangLabelJson()
          .then(response => {
            commit('SET_LABEL', response.data);

            // 메시지 정보
            getLangMessageJson()
              .then(_response => {
                commit('SET_MESSAGE', _response.data);
                resolve()
              })
              .catch(error => {
                reject(error);
              });
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
}

export default lang
